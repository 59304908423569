import React from "react";
import { withPrefix } from "gatsby";
import Layout from "../components/Layout";
import tankstorage from "../images/articles/tankstorage.png";
import gulffire from "../images/articles/gulffire.png";
import innovoil from "../images/articles/innovoil.jpg";
import ifj from "../images/articles/ifj.jpg";
import chemical from "../images/articles/chemical.png";
import fireprot from "../images/articles/ifireprot.png";
import ifw from "../images/articles/ifw.jpg";
import "../styles/global.css";
import "../styles/articles.css";
import "../styles/pageHeader.css";

const data = [
  {
    name: "Tank Storage Magazine",
    img: tankstorage,
    texts: [
      "Pi foam System – Reducing devastating fires from hours to minutes",
      "A case study about the Jurong Island – Pulau Busing terminal fires. (Singapore 2016)",
    ],
    link: withPrefix("/articles/TSM_augsept.pdf"),
  },
  {
    name: "Tank Storage Magazine",
    img: tankstorage,
    texts: [
      "Pi Foam System is featured in the summer issue of Tank Storage Magazine.",
      "”Should the industry rethink storage tank fire protection?” – A Pi Foam System case study about the 2019 ITC Deer Park Fire in Houston.",
    ],
    link: withPrefix("/articles/TSM_junejuly.pdf"),
  },
  {
    name: "Gulf Fire Magazine",
    img: gulffire,
    texts: [
      "Pi foam System – Should we rethink the protection of petrochemical tanks in light of the ITC Deer Park Fire?",
    ],
    link: withPrefix("/articles/GF16.pdf"),
  },
  {
    name: "Gulf Fire Magazine",
    img: gulffire,
    texts: [
      "Pi Foam System is proudly featured in the spring issue of Gulf Fire Magazine",
      "Pi foam System – “TECHNOLOGY AND INNOVATION – A new approach to extinguishing fires on combustible-liquid storage tanks.”",
    ],
    link: withPrefix("/articles/GF14.pdf"),
  },
  {
    name: "Gulf Fire Magazine",
    img: gulffire,
    texts: ["Pi foam System – Protection for installments in conflict zones."],
    link: withPrefix("/articles/InnovOil_June_2018.pdf"),
  },
  {
    name: "Innovoil Magazine",
    img: innovoil,
    texts: [
      "Pi Foam System is proud to be the cover story in the june issue of Innovoil Magazine.",
      "Innovoil is a world leading publication that focuses on innovation and new technology in the oil and gas industry.",
      "„Firefighting with the power of Pi”",
    ],
    link: withPrefix("/articles/Industrial_Fire_Journal.pdf"),
  },
  {
    name: "Industrial Fire Journal",
    img: ifj,
    texts: [
      "We are pleased to announce that Pi Foam System is featured in the latest issue of Industrial Fire Journal.",
      "Industrial Fire Journal is the market-leading international publication covering response and fire protection for the oil and gas industry.",
      "„A new fixed foam system can extinguish a refinery fire, full surface tank fire or rim seal fire without the use of pumps or external energy”",
    ],
    link: withPrefix("/articles/Tank_Storage_Magazine.pdf"),
  },
  {
    name: "Tank Storage Magazine",
    img: tankstorage,
    texts: [
      "Pi Foam System is proudly featured in the latest issue of Tank Storage Magazine.",
      "Tank Storage Magazine is the leading industry publication dedicated exclusively to the bulk liquid storage and terminal sector.",
      "An „innovative foam technology that can extinguish any combustible-liquid in a matter of minutes – regardless of the size of the tank”",
    ],
    link: withPrefix("/articles/Tank_Storage_Magazine.pdf"),
  },
  {
    name: "Chemical Industry Journal",
    img: chemical,
    texts: [
      "Pi Foam System is featured in the summer issue of the Chemical Industry Journal.",
      "„Embrace new technology to fight chemical fires effectively”",
    ],
    link: withPrefix("/articles/CIJ11_Swiss_Fire_DPS.pdf"),
  },
  {
    name: "International Fire Protection Magazine",
    img: fireprot,
    texts: [
      "Pi Foam System is featured in the july issue of International Fire Protection magazine.",
      "International Fire Protection Magazine is the leading publication of the Fire Protection industry.",
      "„A new approach to extinguishing fires on combustible-liquid storage tanks”",
    ],
    link: withPrefix("/articles/IFP74.pdf"),
  },
  {
    name: "Industrial Fire World",
    img: ifw,
    texts: [
      "Pi Foam System is featured in the latest issue of the Industrial Fire World „Self-contained fixed foam system smothers tank fires with precision”",
    ],
    link: withPrefix("/articles/fireword.pdf"),
  },
];

function articles() {
  return (
    <Layout>
      <main className="articles page__container">
        <h1 className="page__title">Articles</h1>
        <div className="articles__wrapper">
          {data.map((d, i) => {
            return (
              <div key={i} className="article__group">
                <div className="article__img">
                  <img src={d.img} alt="magazine logo" />
                </div>
                <div className="article__text">
                  <h2 className="article__tile">{d.name}</h2>
                  {d.texts.map((t) => {
                    return <p className="article__content">{t}</p>;
                  })}
                  <a href={d.link} target="blank">
                    <button>Read whole article</button>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </main>
    </Layout>
  );
}

export default articles;
